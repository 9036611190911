<template>
   <SignUpDetail />
</template>

<script>
import SignUpDetail from "@/base/core/components/authentication/sign-up/SignUpDetail.vue"
export default {
  name: "Contact",
  components: {
  SignUpDetail
  },
  
};
</script>