import { render, staticRenderFns } from "./AccountOrders.vue?vue&type=template&id=6599ebae&scoped=true&"
import script from "./AccountOrders.vue?vue&type=script&lang=js&"
export * from "./AccountOrders.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6599ebae",
  null
  
)

export default component.exports