<template>
  <div
    class="account-address-book"
    :class="{ 'hide-tab-content': isTabShow && isMobileScreen }"
  >

    <h2>{{ $t("change_account_info") }}</h2>
    <div class="back-link" v-if="isMobileScreen" @click="$emit('showTabs')">  <b-icon icon="chevron-left"></b-icon> <span>{{$t ("go_back")}}</span></div>
    <div class="address-block">
      <b-row>
        <b-col sm="12">
          <h3 class="semiBold-18">{{ $t("contact_information") }}</h3>
          <div class="form-wrap">
            <b-form-group
              class="account-inputs"
              id="first-name-group"
              :label="$t('form_first_name') + '*'"
              label-for="first_name"
            >
              <b-form-input
                id="first_name"
                v-model="form.firstname"
                type="text"
                v-on:blur="validateFirstname()"
                :state="form.firstnameState"
                aria-describedby="firstname-feedback"
              ></b-form-input>
              <b-form-invalid-feedback id="firstname-feedback">
                {{ form.firstnameError }}
              </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group
              class="account-inputs"
              id="last-name-group"
              :label="$t('form_last_name') + '*'"
              label-for="last_name"
            >
              <b-form-input
                id="last_name"
                v-model="form.lastname"
                type="text"
                v-on:blur="validateLastname()"
                :state="form.lastnameState"
                aria-describedby="lastname-feedback"
              ></b-form-input>
              <b-form-invalid-feedback id="lastname-feedback">
                {{ form.lastnameError }}
              </b-form-invalid-feedback>
            </b-form-group>

            <b-form-group id="extra-change">
              <div class="form-group left-side">
                <b-form-radio
                  name="extraChange"
                  v-model="currentExtra"
                  value="changeEmail"
                  >{{ $t("change_email") }}</b-form-radio
                >
              </div>
              <div class="form-group left-side">
                <b-form-radio
                  name="extraChange"
                  v-model="currentExtra"
                  value="changePass"
                  >{{ $t("change_password") }}</b-form-radio
                >
              </div>
            </b-form-group>
          </div>
        </b-col>
      </b-row>
    </div>

    <!-- extra -->
    <div v-show="currentExtra == 'changeEmail'">
      <div class="account-address-book">
        <h3 class="semiBold-18">{{ $t("change_email_info") }}</h3>
        <div class="address-block">
          <b-row>
            <b-col md="12">
              <!-- <h3 class="semiBold-18">{{ $t("contact_information") }}</h3> -->
              <div class="form-wrap">
                <b-form-group
                  class="account-inputs"
                  id="email-group"
                  :label="$t('form_email') + '*'"
                  label-for="email"
                >
                  <b-form-input
                    id="email"
                    v-model="form.email"
                    type="text"
                    :state="form.emailState"
                    aria-describedby="email-feedback"
                  ></b-form-input>
                  <b-form-invalid-feedback id="email-feedback">
                    {{ form.emailError }}
                  </b-form-invalid-feedback>
                </b-form-group>
                <b-form-group
                  class="account-inputs"
                  id="password-group"
                  :label="$t('form_password') + '*'"
                  label-for="password"
                >
                  <b-form-input
                    id="password"
                    v-model="form.password"
                    type="password"
                    :state="form.passwordState"
                    aria-describedby="password-feedback"
                  ></b-form-input>
                  <b-form-invalid-feedback id="password-feedback">
                    {{ form.passwordError }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </div>
            </b-col>
          </b-row>
          <!-- <div class="form-footer">
            <b-button
              @click="submit"
              variant="success"
              class="text-white form-submit-btn"
              >Opslaan</b-button
            >
          </div> -->
        </div>
      </div>
    </div>
    <div v-show="currentExtra == 'changePass'">
      <div class="account-address-book">
        <h3 class="semiBold-18">{{ $t("change_password_info") }}</h3>
        <div class="address-block">
          <b-row>
            <b-col md="12">
              <!-- <h3 class="semiBold-18">{{ $t("contact_information") }}</h3> -->
              <div class="form-wrap">
                <b-form-group
                  class="account-inputs"
                  id="password-group"
                  :label="$t('form_current_password') + '*'"
                  label-for="current_password"
                >
                  <b-form-input
                    id="current_password"
                    v-model="form.current_password"
                    type="password"
                    :state="form.current_passwordState"
                    aria-describedby="current_password-feedback"
                  ></b-form-input>
                  <client-only>
                    <password-meter :password="form.current_password" />
                  </client-only>
                  <b-form-invalid-feedback id="current_password-feedback">
                    {{ form.current_passwordError }}
                  </b-form-invalid-feedback>
                </b-form-group>
                <b-form-group
                  class="account-inputs"
                  id="password-group"
                  :label="$t('form_password') + '*'"
                  label-for="password"
                >
                  <b-form-input
                    id="password"
                    v-model="form.password"
                    type="password"
                    :state="form.passwordState"
                    aria-describedby="password-feedback"
                  ></b-form-input>
                  <client-only>
                    <password-meter :password="form.password" />
                  </client-only>
                  <b-form-invalid-feedback id="password-feedback">
                    {{ form.passwordError }}
                  </b-form-invalid-feedback>
                </b-form-group>
                <b-form-group
                  class="account-inputs"
                  id="password_confirm-group"
                  :label="$t('form_password_confirmation') + '*'"
                  label-for="password_confirm"
                >
                  <b-form-input
                    id="password_confirm"
                    v-model="form.password_confirm"
                    type="password"
                    :state="form.password_confirmState"
                    aria-describedby="password_confirm-feedback"
                  ></b-form-input>
                  <b-form-invalid-feedback id="password_confirm-feedback">
                    {{ form.password_confirmError }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </div>
            </b-col>
          </b-row>
          <!-- <div class="form-footer">
        <b-button
          @click="submit"
          variant="success"
          class="text-white form-submit-btn"
          >Opslaan</b-button
        >
      </div> -->
        </div>
      </div>
    </div>

    <!-- submit -->
    <div class="form-footer">
      <b-button
        @click="submit"
        variant="success"
        class="text-white form-submit-btn"
        >{{ $t("save") }}</b-button
      >
    </div>
  </div>
</template>

<script>
//import passwordMeter from "vue-simple-password-meter";
import ClientOnly from "vue-client-only";
import { isServer } from "@storefront/core/helpers";
import { isMobile } from "mobile-device-detect";

export default {
  name: "AccountInformate",
  components: {
    passwordMeter: () =>
      !isServer ? import("vue-simple-password-meter") : null,
    ClientOnly,
    //passwordMeter,
  },

  props: {
    isTabShow: {
      type: Boolean,
    },
  },

  data() {
    return {
      currentExtra: null,
      emailReg:
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
      form: {
        firstname: "",
        firstnameState: null,
        firstnameError: "",
        lastname: "",
        lastnameState: null,
        lastnameError: "",

        //
        email: "",
        emailState: null,
        emailError: "",
        password: "",
        passwordState: null,
        passwordError: "",

        //
        current_password: "",
        current_passwordState: null,
        current_passwordError: "",
        passwordTimer: null,
        password_confirm: "",
        password_confirmState: null,
        password_confirmError: "",
        password_confirmTimer: null,
      },
    };
  },

  computed: {
    user() {
      return this.$store.getters["user/getCurrentUser"];
    },
    //
    Password() {
      return this.form.password;
    },
    PasswordConfirm() {
      return this.form.password_confirm;
    },
    isMobileScreen() {
      return isMobile;
    },
  },
  watch: {
    user() {
      this.form.firstname = this.user.firstname;
      this.form.lastname = this.user.lastname;
      //
      this.form.email = this.user.email;
    },
    //
    Password() {
      this.validatePassword();
    },
    PasswordConfirm() {
      this.validateConfirmationPassword();
    },
  },
  mounted() {
    this.form.firstname = this.user.firstname;
    this.form.lastname = this.user.lastname;
    //
    this.form.email = this.user.email;
  },
  methods: {
    validateEmail() {
      if (this.form.email.length == 0) {
        this.form.emailState = false;
        this.form.emailError = this.$t("email_is_required_field");
        return false;
      }
      if (!this.emailReg.test(this.form.email)) {
        this.form.emailState = false;
        this.form.emailError = this.$t("email_is_invalid_field");
        return false;
      }
      this.form.emailState = true;
      return true;
    },

    validatePassword() {
      if (this.form.password == "") {
        this.form.passwordState = false;
        this.form.passwordError = this.$t("password_is_required_field");
      } else {
        this.form.passwordState = true;
      }
    },
    validateConfirmationPassword() {
      if (this.form.password_confirm.length < 8) {
        this.form.password_confirmState = false;
        this.form.password_confirmError = this.$t("password_to_short");
      } else if (this.form.password != this.form.password_confirm) {
        this.form.password_confirmState = false;
        this.form.password_confirmError = this.$t("password_confirm_not_equal");
      } else {
        this.form.password_confirmState = true;
      }
    },

    validateCurrentPassword() {
      if (this.form.current_password == "") {
        this.form.current_passwordState = false;
        this.form.current_passwordError = this.$t("password_is_required_field");
      } else {
        this.form.current_passwordState = true;
      }
    },

    async submit() {
      let formValid = true;

      if (this.form.firstnameState != true) {
        this.validateFirstname();
        if (this.firstnameState != true) {
          formValid = false;
        }
      }
      if (this.form.lastnameState != true) {
        this.validateLastname();
        if (this.lastnameState != true) {
          formValid = false;
        }
      }

      if (this.currentExtra === "changeEmail") {
        //
        if (this.form.emailState != true) {
          this.validateEmail();
          if (this.emailState != true) {
            formValid = false;
          }
        }
      }

      if (this.currentExtra == "changePass") {
        if (this.form.current_passwordState != true) {
          this.validateCurrentPassword();
          if (this.current_passwordState != true) {
            formValid = false;
          }
        }
        if (this.form.password_confirmState != true) {
          this.validateConfirmationPassword();
          if (this.form.password_confirmState != true) {
            formValid = false;
          }
        }
      }

      if (this.currentExtra) {
        if (this.form.passwordState != true) {
          this.validatePassword();
          if (this.passwordState != true) {
            formValid = false;
          }
        }
      }

      // update
      if (formValid == true) {
        const retval = await this.$store.dispatch("user/updateAccount", {
          account: this.form,
        });

        if (this.currentExtra == "changePass") {
          const retval2 = await this.$store.dispatch("user/changePassword", {
            currentPassword: this.form.current_password,
            newPassword: this.form.password,
          });

          if (retval == true && retval2 == true) {
            const msg = {
              type: "success",
              title: this.$t("account_modify"),
              text: this.$t("account_modified"),
            };
            this.$store.dispatch("messages/sendMessage", { message: msg });
          } else {
            const msg = {
              type: "failure",
              title: this.$t("account_modify"),
              text: this.$t("account_not_modified"),
            };
            this.$store.dispatch("messages/sendMessage", { message: msg });
          }
        }

        if (retval == true) {
          const msg = {
            type: "success",
            title: this.$t("account_modify"),
            text: this.$t("account_modified"),
          };
          this.$store.dispatch("messages/sendMessage", { message: msg });
        } else {
          const msg = {
            type: "failure",
            title: this.$t("account_modify"),
            text: this.$t("account_not_modified"),
          };
          this.$store.dispatch("messages/sendMessage", { message: msg });
        }
      }
    },
    validateFirstname() {
      if (this.form.firstname == "") {
        this.form.firstnameState = false;
        this.form.firstnameError = this.$t("firstname_required");
      } else {
        this.form.firstnameState = true;
      }
    },
    validateLastname() {
      if (this.form.lastname == "") {
        this.form.lastnameState = false;
        this.form.lastnameError = this.$t("lastname_required");
      } else {
        this.form.lastnameState = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>