<template>
  <div>
    <div
      v-if="editAddressId == 0"
      :class="{ 'hide-tab-content': isTabShow && isMobileScreen }"
    >
      <div class="account-address-book">
        <h2>{{ $t("address_book") }}</h2>
        <div class="back-link" v-if="isMobileScreen" @click="$emit('showTabs')">
          <b-icon icon="chevron-left"></b-icon> <span>{{ $t("go_back") }}</span>
        </div>
        <div class="address-block">
          <div class="address-wrap address-page">
            <div class="address-action">
              <div class="address-box">
                <h3 class="semiBold-18">
                  {{ $t("default_billing_address") }}
                </h3>
                <div v-if="defaultBilling == null">
                  {{ $t("no_default_billing_address") }}
                </div>
                <address v-if="defaultBilling != null">
                  <span
                    >{{ defaultBilling.firstname }}
                    {{ defaultBilling.lastname }}</span
                  >
                  <span v-if="defaultBilling.company != null">{{
                    defaultBilling.company
                  }}</span>
                  <span>
                    {{ defaultBilling.street.join(" ") }}
                  </span>
                  <span
                    >{{ defaultBilling.postcode }} {{ defaultBilling.city }}
                  </span>
                  <span class="1">{{
                    countryName(defaultBilling.country_code)
                  }}</span>
                  <span
                    >T:<a :href="`tel:` + defaultBilling.telephone">
                      {{ defaultBilling.telephone }}</a
                    ></span
                  >
                  <span v-if="defaultBilling.vat_id != null"
                    >VAT: {{ defaultBilling.vat_id }}</span
                  >
                </address>
                <b-link
                  class="c-btn c-btn-primary"
                  @click="goToEditAddress(defaultBilling.id)"
                  >{{ $t("edit_address") }}</b-link
                >
              </div>
              <div class="address-box">
                <h3 class="semiBold-18">
                  {{ $t("default_shipping_address") }}
                </h3>
                <div v-if="defaultShipping == null">
                  {{ $t("no_default_shipping_address") }}
                </div>
                <address v-if="defaultShipping != null">
                  <span
                    >{{ defaultShipping.firstname }}
                    {{ defaultShipping.lastname }}</span
                  >
                  <span v-if="defaultShipping.company != null">{{
                    defaultShipping.company
                  }}</span>
                  <span>{{ defaultBilling.street.join(" ") }}</span>
                  <span
                    >{{ defaultShipping.postcode }} {{ defaultShipping.city }}
                  </span>
                  <span class="2">{{
                    countryName(defaultShipping.country_code)
                  }}</span>
                  <span
                    >T:<a :href="`tel:` + defaultShipping.telephone">
                      {{ defaultShipping.telephone }}</a
                    ></span
                  >
                  <span v-if="defaultShipping.vat_id != null"
                    >VAT: {{ defaultShipping.vat_id }}</span
                  >
                </address>
                <b-link
                  class="c-btn c-btn-primary"
                  @click="goToEditAddress(defaultShipping.id)"
                  >{{ $t("edit_address") }}</b-link
                >
              </div>
            </div>
          </div>
        </div>
        <div class="address-block">
          <h3 class="semiBold-18">{{ $t("add_address") }}</h3>
          <div class="address-action">
            <h5
              class="regular-15"
              v-if="addressList && addressList.length === 0"
            >
              U heeft geen andere adressen toegevoegd aan uw adressenboek.
            </h5>

            <div
              class="address-box add-address-row"
              v-for="address in addressList"
              :key="address.id"
            >
              <address>
                <span>{{ address.firstname }} {{ address.lastname }}</span>
                <span v-if="address.company != null">{{
                  address.company
                }}</span>
                <span>{{ address.street.join(" ") }}</span>
                <span>{{ address.postcode }} {{ address.city }} </span>
                <span class="3">{{ countryName(address.country_code) }}</span>
                <span
                  >T:<a :href="`tel:` + address.telephone">
                    {{ address.telephone }}</a
                  ></span
                >
                <span v-if="address.vat_id != null"
                  >VAT:{{ address.vat_id }}</span
                >
              </address>
              <div class="action-wrap">
                <span class="adjust"
                  ><b-link
                    @click="Adjust(address.id)"
                    class="c-btn c-btn-primary"
                    >{{ address.btn.label }}</b-link
                  ></span
                >
                <span class="remove"
                  ><b-link
                    @click="Remove(address.id)"
                    class="c-btn c-btn-primary"
                    >{{ address.btn.label2 }}</b-link
                  ></span
                >
              </div>
            </div>

            <!-- <b-table
              class="address-table"
              responsive
              :items="addressList"
              :fields="fields"
            >
              <template v-slot:cell(btn)="data">
                <span class="adjust"
                  ><b-link @click="Adjust(data.item.id)">{{
                    data.value.label
                  }}</b-link></span
                >
                <span class="remove"
                  ><b-link @click="Remove(data.item.id)">{{
                    data.value.label2
                  }}</b-link></span
                >
              </template>
            </b-table> -->

            <!-- pagination -->
            <!-- <div class="table-pagination">
              <span>Toon</span>
              <div class="select-wrap">
                <b-form-select
                  v-model="selectedOption"
                  :options="options"
                  class="select"
                ></b-form-select>
              </div>
              <span>per pagina</span>
            </div> -->
          </div>
        </div>
        <div class="form-footer manage-address">
          <b-button
            @click="newAddress()"
            type="button"
            class="c-btn c-btn-primary"
            >{{ $t("add_address") }}</b-button
          >
        </div>
      </div>
    </div>
    <AccountEditAddress v-if="editAddressId != 0" />
  </div>
</template>

<script>
import AccountEditAddress from "./AccountEditAddress.vue";
import { isMobile } from "mobile-device-detect";

export default {
  name: "AccountAddressBook",
  components: { AccountEditAddress },

  props: {
    isTabShow: {
      type: Boolean,
    },
  },

  data() {
    return {
      selectedOption: 10,
      options: [10, 20, 30],
      fields: [
        {
          key: "firstname",
          label: this.$t("form_first_name"),
        },
        {
          key: "lastname",
          label: this.$t("form_last_name"),
        },
        {
          key: "street[0]",
          label: this.$t("form_street"),
        },
        {
          key: "city",
          label: this.$t("form_city"),
        },
        {
          key: "country_code",
          label: this.$t("form_country"),
        },
        {
          key: "postcode",
          label: this.$t("form_postal_code"),
        },
        {
          key: "telephone",
          label: this.$t("form_phone"),
        },
        {
          key: "btn",
          label: "",
        },
      ],
    };
  },
  computed: {
    getFilter() {
      return [1, 2, 3, 4, 5, 6];
    },
    tabIndex: {
      get() {
        return this.$store.getters["user/getAccountTabIndex"];
      },
      set(val) {
        this.$store.commit("user/setAccountTabIndex", val);
      },
    },
    editAddressId: {
      get() {
        return this.$store.getters["user/getEditAddressId"];
      },
      set(val) {
        this.$store.commit("user/setEditAddressId", val);
      },
    },
    user() {
      return this.$store.getters["user/getCurrentUser"];
    },
    defaultBilling() {
      return this.$store.getters["user/getDefaultBillingAddress"];
    },
    defaultShipping() {
      return this.$store.getters["user/getDefaultShippingAddress"];
    },
    addressList() {
      if (typeof this.user.addresses != "undefined") {
        const addresses = this.user.addresses;
        const addr = [];
        addresses.forEach((element) => {
          const btn = {
            label: this.$t("edit"),
            label2: this.$t("delete"),
          };
          element.btn = btn;
          addr.push(element);
        });
        return addr;
      }
      return [];
    },
    isMobileScreen() {
      return isMobile;
    },
  },

  methods: {
    newAddress() {
      this.editAddressId = -1;
    },
    Adjust(id) {
      this.editAddressId = id;
    },
    Remove(id) {
      this.$store.dispatch("user/deleteAddress", { id: id });
    },
    goToEditAddress(id) {
      this.tabIndex = 3;
      this.$store.commit("user/setEditAddressId", id);
    },
    goToEditPassword() {
      this.tabIndex = 4;
    },
    countryName(countrycode) {
      if (countrycode == "NL") {
        return "Netherlands";
      } else countrycode == "BE";
      return "Belgium";
    },
  },
};
</script>

<style lang="scss" scoped>
</style>