<template>
  <div
    class="account-address-book"
    :class="{ 'hide-tab-content': isTabShow && isMobileScreen }"
  >
    <h2>{{ $t("newsletters") }}</h2>
    <div class="back-link" v-if="isMobileScreen" @click="$emit('showTabs')">
      <b-icon icon="chevron-left"></b-icon>
      <span>
        {{ $t("go_back") }}
      </span>
    </div>
    <div class="address-block">
      <h3 class="semiBold-18">{{ $t("modify_newsletters") }}</h3>
      <b-row>
        <b-col sm="12">
          <b-form-checkbox
            id="checkbox-1"
            name="checkbox-1"
            label-for="checkbox1"
            v-model="is_subscribed"
            >{{ $t("newsletter_subscription_selected") }}</b-form-checkbox
          >
        </b-col>
      </b-row>
    </div>

    <div class="form-footer">
      <b-button
        @click="save"
        variant="success"
        class="text-white form-submit-btn"
        >{{ $t("save") }}</b-button
      >
    </div>
  </div>
</template>

<script>
import { Logger } from "@storefront/core/lib/logger";
import { isMobile } from "mobile-device-detect";

export default {
  name: "NewsletterSubscription",
  mounted() {
    Logger.debug("user", "NewsletterSubscription", this.user.is_subscribed)();
    this.is_subscribed = this.user.is_subscribed;
    Logger.debug(
      "this.is_subscribed",
      "NewsletterSubscription",
      this.is_subscribed
    )();
  },

  props: {
    isTabShow: {
      type: Boolean,
    },
  },

  data() {
    return {
      is_subscribed: false,
    };
  },
  computed: {
    getFilter() {
      return [1, 2, 3, 4, 5, 6];
    },
    tabIndex: {
      get() {
        return this.$store.getters["user/getAccountTabIndex"];
      },
      set(val) {
        this.$store.commit("user/setAccountTabIndex", val);
      },
    },
    user() {
      return this.$store.getters["user/getCurrentUser"];
    },
    isMobileScreen() {
      return isMobile;
    },
  },
  watch:{
    user(value){
      this.is_subscribed = value.is_subscribed;
    }
  },
  methods: {
    async save() {
      const retval = await this.$store.dispatch("user/updateUserNewsletter", {
        is_subscribed: this.is_subscribed,
      });

      if (retval == true) {
        let msgText = "";
        if (this.is_subscribed == true) {
          msgText = "U bent succesvol ingeschreven voor onze nieuwsbrief";
        } else {
          msgText = "U bent succesvol uitgeschreven voor onze nieuwsbrief";
        }
        const msg = {
          type: "success",
          title: "",
          text: msgText,
        };
        this.$store.dispatch("messages/sendMessage", { message: msg });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>