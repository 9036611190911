<template>
  <div>
    <NotifyModal
      :show="showNotifyModal"
      :product-sku="notifyModalProduct && notifyModalProduct.sku"
      @close="closeNotifyModal"
    />

    <b-modal
      id="share-wish-modal"
      :title="$t('share_wish_modal_title')"
      hide-footer
      centered
      content-class="share-wish-modal"
    >
      <div>
        <h4>{{ $t("share_wish_title") }}</h4>
        <div>
          <b-form-group label="E-mails" label-for="share-emails">
            <v-select multiple taggable v-model="shareWishInfo.emails">
              <template #no-options> Please Enter Email Address(s)</template>
            </v-select>
            <span
              class="invalid-email"
              v-if="shareWishInfo.emailsState === false"
              >{{ shareWishInfo.emailsError }}
            </span>
          </b-form-group>
          <b-form-group label="Message" label-for="share-message">
            <b-form-textarea
              id="share-message"
              aria-describedby="message-feedback"
              rows="3"
              class="remark-input"
              v-model.trim="shareWishInfo.message"
              :state="shareWishInfo.messageState"
            ></b-form-textarea>
            <b-form-invalid-feedback id="message-feedback"
              >{{ shareWishInfo.messageError }}
            </b-form-invalid-feedback>
          </b-form-group>
          <b-button @click="shareWishlist" class="c-btn c-btn-primary">
            <span>{{ $t("share_wish") }}</span>
          </b-button>
        </div>
      </div>
    </b-modal>

    <section
      class="cart pt-8 wish-list-account"
      v-if="showOrders"
      :class="{ 'hide-tab-content': isTabShow && isMobileScreen }"
    >
      <h2 class="page-title semiBold-18">{{ $t("wishlist") }}</h2>
      <h3 class="semiBold-15 text-danger" v-if="lists.length === 0">
        {{ $t("no_whishlist") }}
      </h3>
      <div class="back-link" v-if="isMobileScreen" @click="$emit('showTabs')">
        <b-icon icon="chevron-left"></b-icon>
        <span>{{ $t("go_back") }}</span>
      </div>
      <div class="wish-list-wrap" v-if="lists.length > 0">
        <div class="title">
          <div class="title-block title-left">
            <label>{{ $t("items") }}</label>
          </div>
          <div class="title-block title-right">
            <label>{{ $t("number") }}</label>
            <label>{{ $t("unit_price_incl") }}</label>
          </div>
        </div>
        <div
          id="cat-item"
          class="wish-list-inner"
          v-for="wishitem of lists"
          :key="wishitem.id"
        >
          <div class="left">
            <div class="img-info">
              <div class="img-info-wrap">
                <div class="remove" @click="removeItem(wishitem.id)">
                  <b-icon icon="trash" aria-hidden="true"></b-icon>
                </div>
                <div class="thumb">
                  <b-link :to="`/${wishitem.product.url_key}`">
                    <img :src="wishitem.product.image.url" />
                  </b-link>
                </div>
                <div class="extra web-view">
                  <b-link :to="`/${wishitem.product.url_key}`">
                    <h2>{{ wishitem.product.name }}</h2>
                  </b-link>
                  <div
                    v-if="
                      typeof wishitem.product.configurable_options !=
                      'undefined'
                    "
                  >
                    <div
                      v-for="(option, index) of getProductConf(
                        wishitem.product.sku
                      )"
                      :key="index"
                      class="size-wrap"
                    >
                      <span class="size">{{ option.option_label }}:</span>
                      <span class="size-box">{{ option.value_label }}</span>
                    </div>
                  </div>
                  <!-- <label>Size: Xl</label> -->
                  <label
                    class="stock-status"
                    :class="
                      wishitem.product.stock_status === 'IN_STOCK'
                        ? 'in-stock'
                        : 'out-stock'
                    "
                    >{{
                      $t(
                        wishitem.product.stock_status === "IN_STOCK"
                          ? "In Stock"
                          : "Out of Stock"
                      )
                    }}
                  </label>

                  <div
                    v-if="!!wishitem.description"
                    class="comment-area-wrap web-view"
                  >
                    <template v-if="!openedRemarks[wishitem.id]">
                      <div class="view-comment-area">
                        <label>{{ $t("remark") }}:</label>
                        <p>
                          {{ wishitem.description }}
                        </p>
                      </div>
                      <b-link
                        class="edit-comment"
                        @click="showWishRemark(wishitem.id)"
                      >
                        <!-- v-b-toggle="'remark' + wishitem.id" -->
                        <font-awesome-icon icon="edit"></font-awesome-icon>
                        <span>{{ $t("edit") }}</span>
                      </b-link>

                      <b-link
                        @click="removeRemark(wishitem.id)"
                        class="remove-comment"
                      >
                        <b-icon icon="trash" aria-hidden="true"></b-icon>
                        <span>{{ $t("remove_wishlist_remark") }}</span>
                      </b-link>
                    </template>
                    <template v-else>
                      <label>{{ $t("remark") }}:</label>
                    </template>

                    <b-collapse
                      :id="'remark' + wishitem.id"
                      class="open-comment"
                      :visible="openedRemarks[wishitem.id]"
                    >
                      <b-form-textarea
                        rows="3"
                        class="remark-input"
                        @input="($event) => $set(remarks, wishitem.id, $event)"
                        :value="wishitem.description"
                      ></b-form-textarea>
                      <div class="act-wrap">
                        <b-button
                          class="c-btn c-btn-primary"
                          @click="closeWishRemark(wishitem.id)"
                          >{{ $t("cancel") }}</b-button
                        >
                        <b-button
                          class="c-btn c-btn-primary"
                          :disabled="
                            !remarks[wishitem.id] ||
                            remarks[wishitem.id] === wishitem.description
                          "
                          @click="addRemark(wishitem.id)"
                          >{{ $t("save") }}</b-button
                        >
                      </div>
                    </b-collapse>
                  </div>
                  <div v-else class="default-add-comment web-view">
                    <!-- v-b-toggle="'remark' + wishitem.id" -->
                    <b-button
                      v-if="!openedRemarks[wishitem.id]"
                      class="c-btn c-btn-primary add-comment-btn"
                      @click="showWishRemark(wishitem.id)"
                      >{{ $t("add_wishlist_remark") }}</b-button
                    >
                    <label v-else>{{ $t("remark") }}:</label>

                    <b-collapse
                      :id="'remark' + wishitem.id"
                      class="open-comment"
                      :visible="openedRemarks[wishitem.id]"
                    >
                      <b-form-textarea
                        rows="3"
                        max-rows="6"
                        class="remark-input"
                        v-model="remarks[wishitem.id]"
                      ></b-form-textarea>
                      <div class="act-wrap">
                        <b-button
                          class="c-btn c-btn-primary"
                          @click="closeWishRemark(wishitem.id)"
                          >{{ $t("cancel") }}</b-button
                        >
                        <b-button
                          class="c-btn c-btn-primary submit-comment"
                          :disabled="!remarks[wishitem.id]"
                          @click="addRemark(wishitem.id)"
                          >{{ $t("save") }}</b-button
                        >
                      </div>
                    </b-collapse>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="right">
            <div class="extra mobile-view">
              <b-link :to="`/${wishitem.product.url_key}`">
                <h2>{{ wishitem.product.name }}</h2>
              </b-link>
              <div
                v-if="
                  typeof wishitem.product.configurable_options != 'undefined'
                "
              >
                <div
                  v-for="(option, index) of getProductConf(
                    wishitem.product.sku
                  )"
                  :key="index"
                  class="size-wrap"
                >
                  <span class="size">{{ option.option_label }}:</span>
                  <span class="size-box">{{ option.value_label }}</span>
                </div>
              </div>
              <!-- <label>Size: Xl</label> -->
            </div>
            <div class="right-wrap">
              <div class="count-wrap">
                <div
                  class="count"
                  v-if="wishitem.product.stock_status === 'IN_STOCK'"
                >
                  <div class="quantity-input">
                    <b-link
                      class="step-down-btn"
                      @click="wishitem.qty > 1 && wishitem.qty--"
                    ></b-link>
                    <input
                      type="number"
                      min="1"
                      :value="wishitem.qty"
                      name="quantity"
                      :ref="'wishitem-' + wishitem.id"
                      disabled
                    />
                    <b-link
                      class="step-up-btn"
                      @click="quantityUp(wishitem)"
                    ></b-link>
                  </div>
                </div>
                <div
                  v-if="!!wishitem.description"
                  class="comment-area-wrap mobile-view"
                >
                  <template v-if="!openedRemarks[wishitem.id]">
                    <div class="view-comment-area">
                      <label>{{ $t("remark") }}:</label>
                      <p>
                        {{ wishitem.description }}
                      </p>
                    </div>
                    <b-link
                      class="edit-comment"
                      @click="showWishRemark(wishitem.id)"
                    >
                      <!-- v-b-toggle="'remark' + wishitem.id" -->
                      <font-awesome-icon icon="edit"></font-awesome-icon>
                      <span>{{ $t("edit") }}</span>
                    </b-link>

                    <b-link
                      @click="removeRemark(wishitem.id)"
                      class="remove-comment"
                    >
                      <b-icon icon="trash" aria-hidden="true"></b-icon>
                      <span>{{ $t("remove_wishlist_remark") }}</span>
                    </b-link>
                  </template>
                  <template v-else>
                    <label>{{ $t("remark") }}:</label>
                  </template>

                  <b-collapse
                    :id="'remark' + wishitem.id"
                    :visible="openedRemarks[wishitem.id]"
                    class="open-comment"
                  >
                    <b-form-textarea
                      rows="3"
                      class="remark-input"
                      @input="($event) => $set(remarks, wishitem.id, $event)"
                      :value="wishitem.description"
                    ></b-form-textarea>
                    <div class="act-wrap">
                      <b-button
                        class="c-btn c-btn-primary"
                        @click="closeWishRemark(wishitem.id)"
                        >{{ $t("cancel") }}</b-button
                      >
                      <b-button
                        class="c-btn c-btn-primary"
                        :disabled="
                          !remarks[wishitem.id] ||
                          remarks[wishitem.id] === wishitem.description
                        "
                        @click="addRemark(wishitem.id)"
                        >{{ $t("save") }}</b-button
                      >
                    </div>
                    <!-- :class="{
                          disabled: remarks[wishitem.id] == '',
                        }" -->
                  </b-collapse>
                </div>
                <div v-else class="default-add-comment mobile-view">
                  <!-- v-b-toggle="'remark' + wishitem.id" -->
                  <b-button
                    v-if="!openedRemarks[wishitem.id]"
                    class="c-btn c-btn-primary add-comment-btn"
                    @click="showWishRemark(wishitem.id)"
                    >{{ $t("add_wishlist_remark") }}</b-button
                  >

                  <label v-else>{{ $t("remark") }}:</label>

                  <b-collapse
                    :id="'remark' + wishitem.id"
                    :visible="openedRemarks[wishitem.id]"
                  >
                    <b-form-textarea
                      rows="3"
                      max-rows="6"
                      class="remark-input"
                      v-model="remarks[wishitem.id]"
                    ></b-form-textarea>
                    <div class="act-wrap">
                      <b-button
                        class="c-btn c-btn-primary"
                        @click="closeWishRemark(wishitem.id)"
                        >{{ $t("cancel") }}</b-button
                      >
                      <b-button
                        class="c-btn c-btn-primary submit-comment"
                        :disabled="!remarks[wishitem.id]"
                        @click="addRemark(wishitem.id)"
                        >{{ $t("save") }}</b-button
                      >
                    </div>
                  </b-collapse>
                </div>
                <div class="add-cart mobile-view right-side">
                  <b-link
                    class="btn btn-success"
                    v-if="wishitem.product.stock_status === 'IN_STOCK'"
                    @click="addToCart(wishitem)"
                  >
                    <i class="fas fa-shopping-bag"></i>
                  </b-link>
                  <div
                    v-else
                    class="btn btn-success"
                    @click="addToNotify(wishitem)"
                    key="notify-btn"
                  >
                    <i class="fas fa-envelope"></i>
                  </div>
                </div>
              </div>
              <div class="price">
                <label>
                  {{
                    formatCurrency(
                      wishitem.product.price_range.minimum_price.final_price
                        .value
                    )
                  }}
                </label>
              </div>
            </div>
            <div class="add-cart web-view">
              <b-link
                class="btn btn-success"
                v-if="wishitem.product.stock_status == 'IN_STOCK'"
                @click="addToCart(wishitem)"
              >
                <i class="fas fa-shopping-bag"></i>
                <span>{{ $t("add_to_cart") }}</span>
              </b-link>
              <div
                v-else
                class="btn btn-success"
                @click="addToNotify(wishitem)"
                key="notify-btn"
              >
                <i class="fas fa-envelope"></i>
                <span>{{ $t("stock_update") }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="total-part" v-if="lists.length > 0">
        <button @click="emptyWishlist" class="delete-all-link">
          <b-icon icon="trash" aria-hidden="true"></b-icon>
          <span>{{ $t("clear_all") }}</span>
        </button>
        <b-link
          @click="$bvModal.show('share-wish-modal')"
          class="btn btn-primary"
        >
          Deel verlanglijst
        </b-link>
      </div>

      <div class="cst-pagination-bar" v-if="lists.length > 0">
        <label>
          Pagina
          <span>{{ paginationConf.currentPageNr }}</span> van
          <span>{{ Math.ceil(rows / paginationConf.perPage) }}</span>
        </label>

        <!-- pagination -->
        <b-pagination
          v-model="paginationConf.currentPageNr"
          :total-rows="rows"
          :per-page="paginationConf.perPage"
          class="cst-pagination"
          aria-controls="cat-item"
          @change="tmp"
        >
          <template #page="{ page }">
            <b-link class="page-link">
              {{ page }}
            </b-link>
          </template>
        </b-pagination>
      </div>

      <!-- per page -->
      <div class="pagination-bar-down" v-if="lists.length > 0">
        <div class="left">
          <div class="display-drop">
            <label>{{ $t("view") }}:</label>
            <select v-model.number="paginationConf.perPage">
              <!-- <option value="1">1</option> -->
              <option value="1">1</option>
              <option value="9">9</option>
              <option value="24">24</option>
              <option value="48">48</option>
              <option value="72">72</option>
              <option value="98">98</option>
              <option value="120">120</option>
            </select>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import VSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import Cart from "@storefront/core/modules/cart/mixins";
import { mapGetters } from "vuex";
import { isMobile } from "mobile-device-detect";

import NotifyModal from "@/base/core/components/common/NotifyModal.vue";

export default {
  name: "wishlistPage",
  components: {
    VSelect,
    NotifyModal,
  },
  mixins: [Cart],

  props: {
    isTabShow: {
      type: Boolean,
    },
  },

  data() {
    return {
      showOrders: true,
      showNotifyModal: false,
      notifyModalProduct: null,
      paginationConf: {
        currentPageNr: 1,
        totalCount: 0,
        perPage: 9,
      },
      noOfItems: 1,
      couponCode: "",
      remarks: {},
      openedRemarks: {},
      shareWishInfo: {
        emails: [],
        emailsState: null,
        emailsError: "",
        message: "",
        messageState: null,
        messageError: "",
      },
      prices: [
        {
          id: "price1",
          name: "Verzendkosten (Verzenden - Via PostNL of DHL)",
          cost: "€ 0,00",
          timeDelivery: "Levertijd: 2 tot 4 werkdagen.",
        },
      ],
    };
  },

  mounted() {
    // this.$store.dispatch("account/getSharedWishlist");
  },

  computed: {
    ...mapGetters({
      related: "product/getRelated",
      cartItems: "cart/getCartItems",
    }),
    freeShippingAmount() {
      return this.$store.getters["cart/getFreeShippingAmount"];
    },
    rows() {
      return this.wishlistItems.length;
    },
    lists() {
      if (this.wishlistItems.length === 0) {
        return [];
      }
      const items = this.wishlistItems;

      // Return just page of items needed
      return items.slice(
        (this.paginationConf.currentPageNr - 1) * this.paginationConf.perPage,
        this.paginationConf.currentPageNr * this.paginationConf.perPage
      );
    },
    isMobileScreen() {
      return isMobile;
    },
  },
  methods: {
    formatCurrency(value) {
      var formatter = new Intl.NumberFormat("nl-NL", {
        style: "currency",
        currency: "EUR",
      });
      return formatter.format(value);
    },
    tmp(id) {
      this.showOrders = false;
      setTimeout(() => {
        this.showOrders = true;
        this.paginationConf.currentPageNr = id;
      }, 0.001);
    },
    // openWishRemark(wishId) {
    //   this.$root.$emit('bv::toggle::collapse', 'remark' + wishId);
    // },
    // closeWishRemark(wishId) {},
    showWishRemark(wishId) {
      if (!this.openedRemarks[wishId]) {
        this.$set(this.openedRemarks, wishId, true);
      }
    },
    closeWishRemark(wishId) {
      if (this.openedRemarks[wishId]) {
        this.$set(this.openedRemarks, wishId, false);
      }
    },
    async addToCart(wishitem) {
      if (
        wishitem.product &&
        wishitem.product.__typename == "SimpleProduct" &&
        !wishitem.product.backorders_allowed
      ) {
        if (typeof wishitem.product.stock_qty != "undefined") {
          let quantity = wishitem.qty;
          const existingProduct = this.cartItems.find(
            (item) => wishitem.product.sku == item.product.sku
          );
          if (existingProduct) {
            quantity += existingProduct.quantity;
          }
          if (quantity > wishitem.product.stock_qty) {
            const msg = {
              type: "danger",
              title: this.$t("shopping_basket"),
              text: this.$t("product_not_in_stock_msg", {
                numberOf: wishitem.product.stock_qty,
              }),
            };
            this.$store.dispatch("messages/sendMessage", { message: msg });
            return;
          }
        }
      }

      if (wishitem.product.__typename === "SimpleProduct") {
        const item =
          '{data: {sku: "' +
          wishitem.product.sku +
          '", quantity:' +
          wishitem.qty +
          "}}  ";
        let retval = await this.$store.dispatch("cart/addToCart", {
          type: wishitem.product.__typename,
          item: item,
        });
        if (retval == false) {
          const msg = {
            type: "danger",
            title: this.$t("shopping_basket"),
            text: this.$t("not_add_product"),
          };
          this.$store.dispatch("messages/sendMessage", { message: msg });
        } else {
          const msg = {
            type: "success",
            title: this.$t("shopping_basket"),
            text: this.$t("added_product"),
          };
          this.$store.dispatch("messages/sendMessage", { message: msg });
        }
      } else {
        this.$router.push(`/${wishitem.product.url_key}`);
      }
    },
    getProductConf(productSku) {
      const product = this.cartItems.find(
        (el) => el.product.sku === productSku
      );
      if (!product) return [];
      return product.configurable_options;
    },
    removeItem(id) {
      this.$store.dispatch("user/removeProductWishlist", {
        id: id,
      });
    },
    emptyWishlist() {
      this.wishlistItems.forEach((el) => this.removeItem(el.id));
    },
    async addCoupon() {
      if (this.couponCode != "") {
        const retval = await this.$store.dispatch("cart/addCouponCode", {
          code: this.couponCode,
        });
        if (retval == true) {
          const msg = {
            type: "success",
            title: this.$t("coupon_code"),
            text: this.$t("coupon_code_ok", { code: this.couponCode }),
          };
          this.$store.dispatch("messages/sendMessage", { message: msg });
          this.couponCode = "";
        }
      }
    },
    addRemark(wishitemId) {
      const description = this.remarks[wishitemId];

      // if (description !== "" && typeof description !== "undefined") {
      this.updateWishItemDesc(wishitemId, description);
      // }
    },
    async removeRemark(wishItemId) {
      this.$set(this.remarks, wishItemId, "");
      const retval = await this.$store.dispatch(
        "account/addWishItemDescription",
        { wishItemId, description: "" }
      );
      if (retval === true) {
        this.$store.dispatch("user/loadUser");
        const msg = {
          type: "success",
          title: "",
          text: this.$t("remark_not_added"),
        };
        this.$store.dispatch("messages/sendMessage", { message: msg });
      }
    },
    async updateWishItemDesc(wishItemId, description) {
      const retval = await this.$store.dispatch(
        "account/addWishItemDescription",
        { wishItemId, description }
      );
      if (retval === true) {
        await this.$store.dispatch("user/loadUser");

        this.openedRemarks[wishItemId] = false;

        this.closeWishRemark(wishItemId);

        // this.$root.$emit("bv::toggle::collapse", "remark" + wishItemId);

        const msg = {
          type: "success",
          title: "",
          text: this.$t("remark_added"),
        };
        this.$store.dispatch("messages/sendMessage", { message: msg });
      } else {
        const msg = {
          type: "danger",
          title: "",
          text: this.$t("remark_not_added"),
        };
        this.$store.dispatch("messages/sendMessage", { message: msg });
      }
    },
    async shareWishlist() {
      let { emails, message } = this.shareWishInfo;

      let customer_name = "";

      const user = this.$store.getters["user/getCurrentUser"];

      if (user) {
        customer_name = user.firstname + " " + user.lastname;
      }

      let isOk = true;

      if (emails.length === 0) {
        isOk = false;
        this.shareWishInfo.emailsState = false;
        this.shareWishInfo.emailsError = this.$t("required_field");
      }

      if (message == "") {
        isOk = false;
        this.shareWishInfo.messageState = false;
        this.shareWishInfo.messageError = this.$t("required_field");
      }

      if (isOk) {
        const retval = await this.$store.dispatch("account/shareWishlist", {
          name: customer_name,
          emails,
          message,
        });
        if (retval != false) {
          if (retval.errors.length > 0) {
            retval.errors.forEach((element) => {
              const msg = {
                type: "danger",
                title: "",
                text: element.message,
              };
              this.$store.dispatch("messages/sendMessage", { message: msg });
            });
          } else {
            const msg = {
              type: "success",
              title: "",
              text: this.$t("wish_success"),
            };
            this.$store.dispatch("messages/sendMessage", { message: msg });
          }
          this.$bvModal.hide("share-wish-modal");
        }
      }
    },
    isInCart(wishItem) {
      return this.cartItems.find(
        (item) => item.product.sku === wishItem.product.sku
      );
    },
    closeNotifyModal() {
      this.notifyModalProduct = null;
      this.showNotifyModal = false;
    },
    addToNotify(wishItem) {
      this.notifyModalProduct = wishItem.product;
      this.showNotifyModal = true;
    },
    quantityUp(wishitem) {
      if (wishitem.qty < wishitem.product.stock_qty) {
        wishitem.qty++;
      } else {
        const msg = {
          type: "danger",
          title: this.$t("shopping_basket"),
          text: this.$t("product_not_in_stock_msg", {
            numberOf: wishitem.product.stock_qty,
          }),
        };
        this.$store.dispatch("messages/sendMessage", { message: msg });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
