<template>
  <div class="user-account-section">
    <Breadcrumbs />
    <b-container class="account-page">
      <div class="account-page-title">
        {{ $t("my_account") }}
      </div>
      <b-tabs
        pills
        card
        vertical
        v-model="tabIndex"
        :nav-wrapper-class="{
          'cst-tab': true,
          sidebarhide: isMobileScreen,
        }"
      >
        <!--  -->
        <!--  -->
        <div>
          <b-tab @click="hideTabs" :title="accountTabs[0]"
            ><AccountMyAccount @showTabs="showTabs" :isTabShow="isTabShow" />
          </b-tab>
        </div>
        <div>
         <b-tab @click="hideTabs(); showOrderList = true" :title="accountTabs[1]">
            <b-card-text>
              <AccountOrders @showTabs="showTabs" @chageView="(value) => showOrderList = value" :isTabShow="isTabShow"
                :showOrders="showOrderList" />
            </b-card-text>
          </b-tab>
        </div>
        <b-tab @click="hideTabs" :title="accountTabs[2]"
          ><b-card-text>
            <AccountWishlist
              @showTabs="showTabs"
              :isTabShow="isTabShow"
            /> </b-card-text
        ></b-tab>
        <b-tab @click="hideTabs" :title="accountTabs[3]">
          <AccountAddressBook @showTabs="showTabs" :isTabShow="isTabShow" />
        </b-tab>
        <b-tab @click="hideTabs" :title="accountTabs[4]">
          <AccountInformation @showTabs="showTabs" :isTabShow="isTabShow" />
        </b-tab>
        <!-- <b-tab :title="$t('change_email')">
          <AccountEmail />
        </b-tab>
        <b-tab :title="$t('change_password')">
          <AccountPassword />
        </b-tab> -->
        <!-- <b-tab :title="$t('my_product_reviews')">
          <b-card-text><AccountReviews /></b-card-text>
        </b-tab> -->
        <b-tab @click="hideTabs" :title="accountTabs[5]">
          <NewsletterSubscription @showTabs="showTabs" :isTabShow="isTabShow" />
        </b-tab>
        <b-tab @click="goLogout" :title="accountTabs[6]"> </b-tab>
      </b-tabs>
    </b-container>
  </div>
</template>

<script>
import Breadcrumbs from "@/base/core/components/breadcrumb/Breadcrumbs.vue";
import AccountInformation from "@/base/core/components/account/AccountInformation.vue";
// import AccountEmail from "@/base/core/components/account/AccountEmail.vue";
// import AccountPassword from "@/base/core/components/account/AccountPassword.vue";
import AccountMyAccount from "@/base/core/components/account/AccountMyAccount.vue";
import AccountAddressBook from "@/base/core/components/account/AccountAddressBook.vue";
import NewsletterSubscription from "@/base/core/components/account/NewsletterSubscription.vue";
import AccountWishlist from "@/base/core/components/account/AccountWishlist.vue";
import AccountOrders from "@/base/core/components/account/AccountOrders.vue";
import { isMobile } from "mobile-device-detect";
import config from "@config";

// import AccountReviews from "@/base/core/components/AccountReviews";

//import { Logger } from "@storefront/core/lib/logger";

export default {
  name: "Account",
  components: {
    Breadcrumbs,
    AccountInformation,
    // AccountEmail,
    // AccountPassword,
    AccountAddressBook,
    AccountMyAccount,
    NewsletterSubscription,
    AccountWishlist,
    AccountOrders,
    //AccountReviews,
  },
  computed: {
    isLoggedIn() {
      return this.$store.getters["user/getIsLoggedIn"];
    },
    tabIndex: {
      get() {
        return this.$store.getters["user/getAccountTabIndex"];
      },
      set(val) {
        this.$store.commit("user/setAccountTabIndex", val);
        this.setBreadcrumb(val);
      },
    },

    isMobileScreen() {
      return isMobile;
    },
  },

  watch: {
    /*
    isLoggedIn(newVal, oldVal) {
      if (this.$store.getters["user/getIsLoggedIn"] == false) {
        this.$router.push({ name: "login" });
      }
    },
    */
  },
  mounted() {
    this.tabIndex = 0;
    // const bcrumb = { current: this.$t("account"), routes: [] };
    // this.$store.commit("breadcrumbs/set", bcrumb);
    /*
    if (this.$store.getters["user/getIsLoggedIn"] == false) {
      this.$router.push({ name: "login" });
    }
    */
  },
  data() {
    return {
      isTabShow: true,
      statusAddForm: true,
       showOrderList: true,
      thanksActive: false,
      accountTabs: {
        0: "Dashboard",
        1: this.$t("my_orders"),
        2: this.$t("my_wishlist"),
        3: this.$t("address_book"),
        4: this.$t("account_information"),
        5: this.$t("newsletters"),
        6: this.$t("logout"),
      },
      form: {
        input1: "",
        input2: "",
        input3: "",
        input4: "",
        input5: "",
        input6: "",
        input7: "",
        input8: "",
        input9: "",
        checkbox2: false,
      },
    };
  },
  methods: {
    hideTabs() {
      this.isTabShow = false;
    },
    showTabs() {
      this.isTabShow = true;
    },
    setBreadcrumb(tabIndex = 0) {
      const bcrumb = {
        current: this.accountTabs[tabIndex],
        routes: [{ name: this.$t("my_account"), router_link: "" }],
      };
      this.$store.commit("breadcrumbs/set", bcrumb);
    },
    goLogout() {
      this.visible = false;
      this.tabIndex = 0;
      this.$store.dispatch("user/logout");
      this.$router.push("/");
    },
    formSubmit() {
      this.thanksActive = true;
    },
    formSubmitValidate() {},
  },
  metaInfo() {
    return {
      meta: [
        {
          name: "title",
          content: this.$t("my_account"),
        },
        {
          name: "keywords",
          content: this.$t("my_account"),
        },
        {
          name: "description",
          content: this.$t("my_account"),
        },
      ],
      //test 3
      // if no subcomponents specify a metaInfo.title, this title will be used
      title: this.$t("my_account"),
      // all titles will be injected into this template
      link: [{ rel: "canonical", href: config.app_url + this.$route.fullPath }],
    };
  },
};
</script>

<style lang="scss">
</style>
